import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    checkLoginAPI,
    loginAuthenticationAPI,
    logoutUserAPI,
} from '../services/auth.service';

export const checkLogin = createAsyncThunk(
    'auth/checkLogin',
    async (payload, thunkAPI) => {
        const loginInfo = await checkLoginAPI();
        if (loginInfo.loggedIn) {
            return loginInfo;
        } else {
            thunkAPI.rejectWithValue('Unauthorized. Please login.');
            throw new Error('Unauthorized. Please login.');
        }
    },
);

export const loginUser = createAsyncThunk(
    'auth/login',
    async (payload, thunkAPI) => {
        const loginInfo = await loginAuthenticationAPI();
        if (loginInfo.loggedIn) {
            return loginInfo;
        } else {
            thunkAPI.rejectWithValue('Unauthorized. Please login.');
            throw new Error('Unauthorized. Please login.');
        }
    },
);

export const logoutUser = createAsyncThunk(
    'auth/logoutUser',
    async (payload, thunkAPI) => {
        await logoutUserAPI();
        return true;
    },
);

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        loggedIn: false,
        accessToken: undefined,
    },
    reducers:{
        addAccessToken: (state,action)=>{
            state.accessToken = action.payload.access_token;
            state.loggedIn = true;
        }
    },
    extraReducers: {
        [loginUser.fulfilled.type]: (state, action) => {
            state.loggedIn = true;
        },
        [loginUser.rejected.type]: (state, action) => {
            state = {
              loggedIn: false,
              accessToken: undefined
            };
        },
        [checkLogin.fulfilled.type]: (state, action) => {
            state.loggedIn = true;
            state.accessToken = action.payload.access_token
        },
        [logoutUser.fulfilled.type]: (state, action) => {
            state.loggedIn = false;
        },
    },
});
export const { addAccessToken } = authSlice.actions;
export default authSlice.reducer;