import React from 'react';
import PrimaryRoutes from "./router/primary.routes";
import { BrowserRouter } from 'react-router-dom';
import './App.css';

function App() {
  return (
    <div className="App" style={{height: '100%'}}>
      <BrowserRouter>
        <PrimaryRoutes/>
      </BrowserRouter>
    </div>
  );
}

export default App;
