import React, { useEffect, useState } from 'react';
import { List, Card, Image, Modal, Button, Carousel, Popconfirm } from 'antd';
import { Tutorial } from '../../data/models/tutorial.model';

import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../data/storeConfig';
import * as tutorialActions from '../../data/reducers/tutorials.reducer';
import { useSelector } from 'react-redux';
import { RootState } from '../../data/reducers';
import { getImageUrl, getYoutubeVideoId } from '../../shared/utils/common';
const { Meta } = Card;

const VideoTutorialsListView = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { videoTutorials, videoTutorialsLoading, videoTutorialsError } = useSelector(
    (state: RootState) => state.tutorials,
  );

  useEffect(() => {
    dispatch(tutorialActions.fetchTutorialsByType({ tutorialType: 'VIDEO_TUTORIAL' }));
  }, []);

  // State to manage the modal visibility and selected tutorial
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedTutorial, setSelectedTutorial] = useState<Tutorial | null>(null);

  // Function to handle opening the modal
  const handleOpenModal = (tutorial: Tutorial) => {
    setSelectedTutorial(tutorial);
    setModalVisible(true);
  };

  // Function to handle closing the modal
  const handleCloseModal = () => {
    setSelectedTutorial(null);
    setModalVisible(false);
  };

  const handleDeleteTutorial = async (data: {tutorialId: string}) => {
    await dispatch(tutorialActions.deleteTutorial({tutorialId: data.tutorialId}));
    dispatch(tutorialActions.fetchTutorialsByType({ tutorialType: 'VIDEO_TUTORIAL' }));
    handleCloseModal();
  };

  return (
    <div>
      {videoTutorialsLoading ? (
        <p>Loading...</p>
      ) : videoTutorialsError ? (
        <p>Error loading tutorials</p>
      ) : (
        <div>
          <List
            grid={{ gutter: 16, column: 3 }}
            dataSource={videoTutorials}
            renderItem={(tutorial) => (
              <List.Item>
                <Card
                  hoverable={false}
                  style={{
                    marginBottom: 16,
                    maxWidth: 250,
                    minWidth: 160,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                  }}
                >
                  <div style={{ alignSelf: 'center', paddingBottom: 10 }}>
                    <Image alt={tutorial.title} src={getImageUrl(tutorial.thumbnail)} width={150} loading="eager" />
                  </div>

                  <Meta title={`#${tutorial.sequence} ${tutorial.title}`} style={{
                    maxWidth: 220
                  }} />
                  <Button onClick={() => handleOpenModal(tutorial)} style={{ marginTop: 20 }}>
                    View tutorial
                  </Button>
                  <Popconfirm
                    title="Are you sure you want to delete this tutorial?"
                    onConfirm={() => handleDeleteTutorial({tutorialId: tutorial.tutorialId})}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="default" danger style={{ marginTop: 10 }}>
                      Delete
                    </Button>
                  </Popconfirm>
                </Card>
              </List.Item>
            )}
          />

          {/* Modal to display Tutorial Images */}
          <Modal title={selectedTutorial?.title} visible={modalVisible} onCancel={handleCloseModal} footer={null} style={{maxWidth: 400}}>
            <iframe
              width="350"
              height="200"
              src={`https://www.youtube.com/embed/${getYoutubeVideoId(selectedTutorial?.videoUrl ?? 'https://www.google.com/search?q=yufin')}`}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen={true}
              style={{
                border: 'none'
              }}
            ></iframe>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default VideoTutorialsListView;
