import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./auth.reducer";
import dashboardReducer from "./dashboard.reducer";
import tutorialsReducer from "./tutorials.reducer";
import fsareportReducer from "./fsareport.reducer";
import yuorderpromoReducer from "./yuorderpromo.reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer,
  tutorials: tutorialsReducer,
  fsaMerchantreport:fsareportReducer,
  yuorderpromos:yuorderpromoReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;