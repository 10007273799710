declare global {
  interface Window {
    _env_: any;
  }
}

export const constants = {
  KEY_AUTH_TOKEN: "AUTH_KEY",
  KEY_USER_NAME: "USER_NAME",
  endpoint: window?._env_.REACT_APP_API_ENDPOINT,
  msalClientId: window?._env_.REACT_APP_CLIENT_ID,
  msalAuthority: window?._env_.REACT_APP_AUTHORITY,
  msalScopes: window?._env_.REACT_APP_MSAL_SCOPES?.split(","),
  YUSHOUTOUT_ID: window?._env_.REACT_APP_YUSHOUTOUT_ID,
  SELFIE_CONTEST_ID: window?._env_.REACT_APP_SELFIE_CONTEST_ID,
  MARKETPLACE_ID: window?._env_.REACT_APP_MARKETPLACE_ID
};