// src/components/ReportView.js
import React, { useEffect, useState } from 'react';
import { DatePicker, Button, Table, Select, Row, Col, Card } from 'antd';
import * as XLSX from 'xlsx';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../data/storeConfig';
import * as dashboardActions from '../../data/reducers/dashboard.reducer';
import { RootState } from '../../data/reducers';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import { useNavigate } from 'react-router';
import MerchantStoreTable from './components/merchantStoresTable';
import StoreMap from './components/storeMap';
import { GetMerchantStoresReport } from '../../data/reducers/fsareport.reducer';
import Input from 'antd/lib/input/Input';

const { RangePicker } = DatePicker;

const SignupsReportView: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [dateRange, setDateRange] = useState<[Moment | null, Moment | null]>([null, null]);
  const [ambassadorReferralId, setAmbassadorReferralId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const [pageSize, setPageSize] = useState(10);
  const { FSAReportData, FSAReportError, FSAReportLoading } = useSelector((state: RootState) => state.dashboard);

  const fetchData = async () => {
   await dispatch(dashboardActions.fetchFSAReport());
  };

 
  const { fsareport, merchantReport, marketplaceOrdersLoading, marketplaceOrdersError } = useSelector(
    (state: RootState) => state.fsaMerchantreport,
  );
  const [filteredData, setFilteredData] = useState(fsareport);
  // useEffect(() => {
  //   fetchData();
  // }, [filteredData,dispatch]);

  const fetchMerchantStores = (page: number, startDate?: string, endDate?: string, referralId?: string) => {
    const params = {
      key: 'Tx6nuzaGL5jsACkPkQktdG74MNFm9Mh7JvLAymXBk3epNt7T3AAa6F7TbvgeVMvSR6x8d2xKxR63QehyhwzQLbRPT',
      page,
      pageSize,
      refreshReport: false,
      ambassadorReferralId: referralId,
      startDate: startDate || '2021-10-07',
      endDate: endDate || moment().format('YYYY-MM-DD'),
    };
    dispatch(GetMerchantStoresReport(params));
  };

  useEffect(() => {
    fetchMerchantStores(
      currentPage,

      dateRange[0]?.format('YYYY-MM-DD'),
      dateRange[1]?.format('YYYY-MM-DD'),
      ambassadorReferralId,
    );
  }, [currentPage, dateRange, ambassadorReferralId, dispatch, pageSize]); // Add dependencies

  const handleDateRangeChange = (dates: [Moment | null, Moment | null] | null) => {
    setDateRange(dates || [null, null]);
  };
  useEffect(() => {
    setFilteredData(fsareport); // Update filtered data when fsareport changes
  }, [fsareport]);
  const handleSearch = () => {
    setCurrentPage(1); // Reset to page 1 for new filters
    fetchMerchantStores(
      1,

      dateRange[0]?.format('YYYY-MM-DD'),
      dateRange[1]?.format('YYYY-MM-DD'),
      ambassadorReferralId || undefined,
    );
  };
  const handlePageSizeChange = (current: number, size: number) => {
    setPageSize(size); // Update pageSize state
    setCurrentPage(1); // Reset to the first page when page size changes
  };
  const columns = [
    {
      title: 'Store Name',
      dataIndex: 'storeName',
      key: 'storeName',
    },
    {
      title: 'Business Address',
      dataIndex: 'businessAddress',
      key: 'businessAddress',
    },
    {
      title: 'Owner Name',
      dataIndex: 'ownerName',
      key: 'ownerName',
    },
    {
      title: 'Owner Phone',
      dataIndex: 'ownerPhone',
      key: 'ownerPhone',
    },
    // {
    //   title: 'Store Category',
    //   dataIndex: 'storeCategory',
    //   key: 'storeCategory',
    // },
    {
      title: 'ReferralId',
      dataIndex: 'ambassadorReferralId',
      key: 'ambassadorReferralId',
    },
    // {
    //   title: 'Store URL',
    //   dataIndex: 'storeURL',
    //   key: 'storeURL',
    //   render: (text: string) => (
    //     <a href={`https://${text}`} target="_blank" rel="noopener noreferrer">
    //       {text}
    //     </a>
    //   ),
    // },
    {
      title: 'Creation Timestamp',
      dataIndex: 'creationTimestamp',
      key: 'creationTimestamp',
    },
    // {
    //   title: 'Delivery Available',
    //   dataIndex: 'deliveryAvailable',
    //   key: 'deliveryAvailable',
    // },
    // {
    //   title: 'Pickup Available',
    //   dataIndex: 'pickupAvailable',
    //   key: 'pickupAvailable',
    // },
  ];
  const filterData = (referralId: string | null, dates: RangeValue<Moment>) => {
    let data = [...fsareport];

    // Filter by referralId
    if (referralId) {
      data = data.filter((store) => store.ambassadorReferralId === referralId);
    }

    // Filter by date range, ensuring both dates are not null
    if (dates && dates[0] && dates[1]) {
      const [start, end] = dates;
      data = data.filter((store) => moment(store.creationTimestamp).isBetween(start, end, undefined, '[]'));
    }

    setFilteredData(data);
  };

  const tableData = Array.isArray(merchantReport?.data)
    ? merchantReport?.data?.map((store, index) => ({
        key: index,
        merchantStoreId: store.merchantStoreId,
        storeName: store.storeName,
        businessAddress: store.businessAddress,
        ownerName: store.ownerUsers[0]?.fullName || 'N/A',
        ownerPhone: store.ownerUsers[0]?.phoneNumber || 'N/A',
        ambassadorReferralId: store?.ambassadorReferralId,
        // storeCategory: store.storeCategory?.storeCategoryName || 'N/A',
        storeURL: store.storeURL || 'N/A',
        creationTimestamp: new Date(store.creationTimestamp).toLocaleString(),
        deliveryAvailable: store.deliveryAvailable ? 'Yes' : 'No',
        pickupAvailable: store.pickupAvailable ? 'Yes' : 'No',
      }))
    : [];
  console.log('table data', tableData);
  const filterByDateRange = (start: Moment, end: Moment) => {
    // Check if merchantReport and merchantReport.data exist
    if (!merchantReport || !Array.isArray(merchantReport.data)) {
      return [];
    }

    return merchantReport.data.filter(
      (store) => store.creationTimestamp && moment(store.creationTimestamp).isBetween(start, end, undefined, '[]'),
    );
  };
  const today = moment().startOf('day');
  const startOfWeek = moment().startOf('week');
  const startOfMonth = moment().startOf('month');
  const startOfQuarter = moment().startOf('quarter');

  const todaySignups = filterByDateRange(today, moment());
  const weekSignups = filterByDateRange(startOfWeek, moment());
  const monthSignups = filterByDateRange(startOfMonth, moment());
  const quarterSignups = filterByDateRange(startOfQuarter, moment());
  return (
    <div>
      <div>
        {/* Cards for signups */}
        <Row gutter={16}>
          <Col span={6}>
            <Card title="Signups Today" bordered={false}>
              {todaySignups.length}
            </Card>
          </Col>
          <Col span={6}>
            <Card title="Signups This Week" bordered={false}>
              {weekSignups.length}
            </Card>
          </Col>
          <Col span={6}>
            <Card title="Signups This Month" bordered={false}>
              {monthSignups.length}
            </Card>
          </Col>
          <Col span={6}>
            <Card title="Signups This Quarter" bordered={false}>
              {quarterSignups.length}
            </Card>
          </Col>
        </Row>
      </div>
      <div>
        <h1>Merchant Stores Map</h1>
        <StoreMap stores={filteredData} />
      </div>

      <h1>Merchant Stores Report</h1>

      <div style={{ marginBottom: 16 }}>
        <RangePicker
          onChange={handleDateRangeChange}
          value={dateRange}
          format="YYYY-MM-DD"
          style={{ marginRight: 8 }}
        />
        <Input
          placeholder="Ambassador Referral ID"
          value={ambassadorReferralId}
          onChange={(e) => setAmbassadorReferralId(e.target.value)}
          style={{ width: 300, marginRight: 8 }}
        />
        <Button type="primary" onClick={handleSearch}>
          Search
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={tableData}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: merchantReport?.totalFilteredRecords || 0,
          onChange: (page) => setCurrentPage(page),
          onShowSizeChange: handlePageSizeChange, // Updates currentPage and triggers useEffect
        }}
        loading={marketplaceOrdersLoading}
      />

      {marketplaceOrdersError && <div style={{ color: 'red', marginTop: 16 }}>Error fetching merchant stores</div>}
    </div>
  );
};

export default SignupsReportView;
