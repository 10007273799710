import React from 'react';
import { Route, Routes } from 'react-router';
import MarketplaceOrdersList from '../pages/dashboard/marketplace/MarketplaceOrdersList';
import Dashboard from '../pages/dashboard/Dashboard';
import ContestEntriesView from '../pages/dashboard/contests/ContestEntriesView';
import CreateTutorialForm from '../pages/tutorials/CreateTutorialForm';
import Tutorials from '../pages/tutorials/Tutorials';
import TipsAndTricks from '../pages/community/tipsAndTricks/TipsAndtricks';
import CreateTipsForm from '../pages/community/tipsAndTricks/CreateTipsForm';

const TipsAndTricksRouter = () => {
  return (
    <Routes>
      <Route path='/' element={<TipsAndTricks/>}/>
      <Route path='/create' element={<CreateTipsForm/>}/>
    </Routes>
  );
};

export default TipsAndTricksRouter;
