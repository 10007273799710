import axios from 'axios';
import { constants } from '../../config';
import { TipsFormData, TutorialFormData } from '../models/tutorial.model';
import { del, error, get, post, success } from './services.common';

export const fetchTutorialsByTypeService = async (data: { tutorialType: string }) => {
  const url =
    constants.endpoint +
    `/community/tutorials/list-admin?key=W9BBwlJHtV7e1gli2m0PHEUUDyrwEOqVyBzqI9hBRbVIePXKZYDc0l6F9R9Jn1Mo&tutorialType=${data.tutorialType}`;
  const response = await get(url, {});
  return response;
};

export const createTutorialService = async (data: TutorialFormData) => {
  const url = `${constants.endpoint}/community/tutorials/create?key=W9BBwlJHtV7e1gli2m0PHEUUDyrwEOqVyBzqI9hBRbVIePXKZYDc0l6F9R9Jn1Mo`;

  // Create a new FormData object
  const formData = new FormData();

  // Append form fields to the FormData object
  formData.append('tutorialType', data.tutorialType);
  formData.append('sequence', (data.sequence || 0).toString());
  formData.append('title', data.title);

  // Append files to the FormData object
  if (data.thumbnail) {
    formData.append('thumbnail', data.thumbnail);
  }

  if (data.tutorialImages && data.tutorialImages.length > 0) {
    for (let i = 0; i < data.tutorialImages.length; i++) {
      formData.append('tutorialImages', data.tutorialImages[i]);
    }
  }

  if (data.videoUrl) {
    formData.append('videoUrl', data.videoUrl);
  }

  if (data.duration) {
    formData.append('duration', data.duration);
  }

  // Send the FormData object in the Axios POST request
  try {
    const response = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Set the content type for FormData
      },
    });

    return success(response.data);
  } catch (e) {
    // Handle error
    return error(e);
  }
};

export const deleteTutorialService = async (data: { tutorialId: string }) => {
  const url =
    constants.endpoint +
    `/community/tutorials/delete?key=W9BBwlJHtV7e1gli2m0PHEUUDyrwEOqVyBzqI9hBRbVIePXKZYDc0l6F9R9Jn1Mo&tutorialId=${data.tutorialId}`;
  const response = await del(url, {});
  return response;
};


// ==============
export const fetchTipsService = async () => {
  const url =
    constants.endpoint +
    `/community/community/tips-and-tricks/list-admin?key=W9BBwlJHtV7e1gli2m0PHEUUDyrwEOqVyBzqI9hBRbVIePXKZYDc0l6F9R9Jn1Mo`;
  const response = await get(url, {});
  return response;
};

export const createTipsService = async (data: TipsFormData) => {
  const url = `${constants.endpoint}/community/community/tips-and-tricks/create?key=W9BBwlJHtV7e1gli2m0PHEUUDyrwEOqVyBzqI9hBRbVIePXKZYDc0l6F9R9Jn1Mo`;

  // Create a new FormData object
  const formData = new FormData();

  // Append form fields to the FormData object
  formData.append('title', (data.title));
  formData.append('sequence', (data.sequence || 0).toString());

  // Append files to the FormData object
  if (data.thumbnail) {
    formData.append('thumbnail', data.thumbnail);
  }

  if (data.images && data.images.length > 0) {
    for (let i = 0; i < data.images.length; i++) {
      formData.append('images', data.images[i]);
    }
  }

  // Send the FormData object in the Axios POST request
  try {
    const response = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Set the content type for FormData
      },
    });

    return success(response.data);
  } catch (e) {
    // Handle error
    return error(e);
  }
};

export const deleteTipsService = async (data: { tipId: string }) => {
  const url =
    constants.endpoint +
    `/community/community/tips-and-tricks/delete?key=W9BBwlJHtV7e1gli2m0PHEUUDyrwEOqVyBzqI9hBRbVIePXKZYDc0l6F9R9Jn1Mo&tipsAndTricksId=${data.tipId}`;
  const response = await del(url, {});
  return response;
};