import axios from 'axios';
import { constants } from '../../config';

export const api = constants.endpoint;

export const error = (e: any) => {
  return { status: 'error', isSuccessful: false, message: e };
};

export const success = (data: any) => {
  return { status: 'success', isSuccessful: true, data: data };
};

export const getAuthHeaders = () => {
  return {
    Authorization:
      'Bearer ' + (localStorage.getItem(constants.KEY_AUTH_TOKEN)),
  };
};

export const get = async (url: string, headers: any) => {
  try {
    const response = await axios.get(url, { headers });
    return success(response.data);
  } catch (e) {
    console.log(e);
    return error(e);
  }
};

export const post = async (url: string, data: any, headers: any) => {
  try {
    const response = await axios.post(url, data, { headers });
    return success(response.data);
  } catch (e) {
    console.log(e);
    return error(e);
  }
};

export const download = async (url: string, data: any, headers: any) => {
  try {
    const response = await axios.post(url, data, {
      headers,
      responseType: 'blob',
    });
    return success(response.data);
  } catch (e) {
    console.log(e);
    return error(e);
  }
};

export const update = async (url: string, data: any, headers: any) => {
  try {
    const response = await axios.put(url, data, { headers });
    return success(response.data);
  } catch (e) {
    console.log(e);
    return error(e);
  }
};

export const del = async (url: string, headers: any) => {
  try {
    const response = await axios.delete(url, { headers });
    return success(response.data);
  } catch (e) {
    console.log(e);
    return error(e);
  }
};
