import React, { useEffect, useState } from 'react';
import { List, Card, Image, Modal, Button, Carousel, Popconfirm } from 'antd';
import { TipsAndTricks, Tutorial } from '../../../data/models/tutorial.model';

import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../data/storeConfig';
import * as tutorialActions from '../../../data/reducers/tutorials.reducer';
import { useSelector } from 'react-redux';
import { RootState } from '../../../data/reducers';
import { getImageUrl } from '../../../shared/utils/common';
const { Meta } = Card;

const TipsListView = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { tips, tipsLoading, tipsError } = useSelector(
    (state: RootState) => state.tutorials,
  );

  useEffect(() => {
    dispatch(tutorialActions.fetchTips());
  }, []);

  // State to manage the modal visibility and selected tutorial
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedTutorial, setSelectedTutorial] = useState<TipsAndTricks | null>(null);

  // Function to handle opening the modal
  const handleOpenModal = (tutorial: TipsAndTricks) => {
    setSelectedTutorial(tutorial);
    setModalVisible(true);
  };

  // Function to handle closing the modal
  const handleCloseModal = () => {
    setSelectedTutorial(null);
    setModalVisible(false);
  };

  const handleDeleteTips = async (data: {tipId: string}) => {
    await dispatch(tutorialActions.deleteTip({tipId: data.tipId}));
    dispatch(tutorialActions.fetchTips());
    handleCloseModal();
  };

  return (
    <div>
      {tipsLoading ? (
        <p>Loading...</p>
      ) : tipsError ? (
        <p>Error loading tips and tricks</p>
      ) : (
        <div>
          <List
            grid={{ gutter: 16, column: 3 }}
            dataSource={tips}
            renderItem={(tip) => (
              <List.Item>
                <Card
                  hoverable={false}
                  style={{
                    marginBottom: 16,
                    maxWidth: 250,
                    minWidth: 160,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                  }}
                >
                  <div style={{ alignSelf: 'center', paddingBottom: 10 }}>
                    <Image alt={"Image"} src={getImageUrl(tip.thumbnail)} width={150} loading="eager" />
                  </div>

                  <Meta title={`#${tip.sequence} ${tip.title ?? ""}`} />
                  <Button onClick={() => handleOpenModal(tip)} style={{ marginTop: 20 }}>
                    View tutorial
                  </Button>

                  <Popconfirm
                    title="Are you sure you want to delete this record?"
                    onConfirm={() => handleDeleteTips({tipId: tip.tipsAndTricksId})}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="default" danger style={{ marginTop: 10 }}>
                      Delete
                    </Button>
                  </Popconfirm>
                </Card>
              </List.Item>
            )}
          />

          {/* Modal to display Tutorial Images */}
          <Modal title={"Image"} visible={modalVisible} onCancel={handleCloseModal} footer={null}>
            <Carousel>
              {selectedTutorial?.images.map((image, index) => (
                <div key={index}>
                  <Image
                    src={getImageUrl(image)}
                    alt={`Image ${index + 1}`}
                    width="100%"
                    style={{ maxWidth: '100%', maxHeight: '500px', objectFit: 'cover', objectPosition: 'top' }}
                  />
                </div>
              ))}
            </Carousel>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default TipsListView;
