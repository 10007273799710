import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { TipsAndTricks, TipsFormData, Tutorial, TutorialFormData } from '../models/tutorial.model';
import {
  createTipsService,
  createTutorialService,
  deleteTipsService,
  deleteTutorialService,
  fetchTipsService,
  fetchTutorialsByTypeService,
} from '../services/tutorials.service';

export const createTutorial = createAsyncThunk('tutorials/create', async (payload: TutorialFormData, thunkAPI) => {
  const response: any = await createTutorialService(payload);
  if (response.isSuccessful) {
    return response.data;
  } else {
    thunkAPI.rejectWithValue("Couldn't create tutorial");
    throw new Error("Couldn't create tutorial");
  }
});

export const deleteTutorial = createAsyncThunk(
  'tutorials/delete',
  async (payload: { tutorialId: string }, thunkAPI) => {
    const response: any = await deleteTutorialService(payload);
    if (response.isSuccessful) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue("Couldn't delete tutorial");
      throw new Error("Couldn't delete tutorial");
    }
  },
);

export const fetchTutorialsByType = createAsyncThunk(
  'tutorials/fetch',
  async (payload: { tutorialType: 'QUICK_TUTORIAL' | 'VIDEO_TUTORIAL' }, thunkAPI) => {
    let response: any = await fetchTutorialsByTypeService({ tutorialType: payload.tutorialType });
    if (response.isSuccessful) {
      response.data.tutorialType = payload.tutorialType;
      response = response;
      return response.data;
    } else {
      thunkAPI.rejectWithValue("Couldn't create tutorial");
      throw new Error("Couldn't create tutorial");
    }
  },
);

// ========================

export const createTip = createAsyncThunk('tips/create', async (payload: TipsFormData, thunkAPI) => {
  const response: any = await createTipsService(payload);
  if (response.isSuccessful) {
    return response.data;
  } else {
    thunkAPI.rejectWithValue("Couldn't create tips&tricks");
    throw new Error("Couldn't create tips&tricks");
  }
});

export const deleteTip = createAsyncThunk('tips/delete', async (payload: { tipId: string }, thunkAPI) => {
  const response: any = await deleteTipsService(payload);
  if (response.isSuccessful) {
    return response.data;
  } else {
    thunkAPI.rejectWithValue("Couldn't delete tips&tricks");
    throw new Error("Couldn't delete tips&tricks");
  }
});

export const fetchTips = createAsyncThunk('tips/fetch', async (payload, thunkAPI) => {
  const response: any = await fetchTipsService();
  if (response.isSuccessful) {
    return response.data;
  } else {
    thunkAPI.rejectWithValue("Couldn't create tips&tricks");
    throw new Error("Couldn't create tips&tricks");
  }
});

const authSlice = createSlice({
  name: 'tutorials',
  initialState: {
    quickTutorials: <Tutorial[]>[],
    quickTutorialsLoading: false,
    quickTutorialsError: false,
    videoTutorials: <Tutorial[]>[],
    videoTutorialsLoading: false,
    videoTutorialsError: false,
    createTutorialResponse: <Tutorial>{},
    createTutorialRequestLoading: false,
    createTutorialRequestError: false,
    deleteTutorialRequestLoading: false,

    tips: <TipsAndTricks[]>[],
    tipsLoading: false,
    tipsError: false,
    createTipsResponse: <TipsAndTricks>{},
    createTipsRequestLoading: false,
    createTipsRequestError: false,
    deleteTipsRequestLoading: false,
  },
  reducers: {},
  extraReducers: {
    [createTutorial.rejected.type]: (state, action) => {
      state.createTutorialRequestLoading = true;
      state.createTutorialRequestError = false;
    },
    [createTutorial.fulfilled.type]: (state, action) => {
      state.createTutorialRequestLoading = false;
      state.createTutorialRequestError = false;
    },
    [createTutorial.rejected.type]: (state, action) => {
      state.createTutorialRequestLoading = false;
      state.createTutorialRequestError = true;
    },
    [deleteTutorial.rejected.type]: (state, action) => {
      state.deleteTutorialRequestLoading = true;
    },
    [deleteTutorial.fulfilled.type]: (state, action) => {
      state.deleteTutorialRequestLoading = false;
    },
    [deleteTutorial.rejected.type]: (state, action) => {
      state.deleteTutorialRequestLoading = false;
    },
    [fetchTutorialsByType.rejected.type]: (state, action) => {
      if (action.payload.tutorialType == 'QUICK_TUTORIAL') {
        state.quickTutorialsLoading = true;
        state.quickTutorialsError = false;
      } else {
        state.videoTutorialsLoading = true;
        state.videoTutorialsError = false;
      }
    },
    [fetchTutorialsByType.fulfilled.type]: (state, action) => {
      if (action.payload.tutorialType == 'QUICK_TUTORIAL') {
        state.quickTutorialsLoading = false;
        state.quickTutorialsError = false;
        state.quickTutorials = action.payload.data;
      } else {
        state.videoTutorialsLoading = false;
        state.videoTutorialsError = false;
        state.videoTutorials = action.payload.data;
      }
    },
    [fetchTutorialsByType.rejected.type]: (state, action) => {
      if (action.payload.tutorialType == 'QUICK_TUTORIAL') {
        state.quickTutorialsLoading = false;
        state.quickTutorialsError = true;
      } else {
        state.videoTutorialsLoading = false;
        state.videoTutorialsError = true;
      }
    },

    [createTip.rejected.type]: (state, action) => {
      state.createTipsRequestLoading = true;
      state.createTipsRequestError = false;
    },
    [createTip.fulfilled.type]: (state, action) => {
      state.createTipsRequestLoading = false;
      state.createTipsRequestError = false;
    },
    [createTip.rejected.type]: (state, action) => {
      state.createTipsRequestLoading = false;
      state.createTipsRequestError = true;
    },
    [fetchTips.rejected.type]: (state, action) => {
      state.tipsLoading = true;
      state.tipsError = false;
    },
    [fetchTips.fulfilled.type]: (state, action) => {
      state.tipsLoading = false;
      state.tipsError = false;
      state.tips = action.payload.data;
    },
    [fetchTips.rejected.type]: (state, action) => {
      state.tipsLoading = false;
      state.tipsError = true;
    },
  },
});

export default authSlice.reducer;
