import React, { useEffect, useState } from 'react';
import { Form, Input, Radio, Button, Upload, message, notification } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { TutorialFormData } from '../../data/models/tutorial.model';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../data/reducers';
import * as tutorialActions from '../../data/reducers/tutorials.reducer';
import { AppDispatch } from '../../data/storeConfig';
import { useNavigate } from 'react-router';

const { Item } = Form;

const CreateTutorialForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState(false);
  const [tutorialType, setTutorialType] = useState<'QUICK_TUTORIAL' | 'VIDEO_TUTORIAL' | null>(null);
  const [thumbnailFile, setThumbnailFile] = useState<File>();
  const [tutorialImageFiles, setTutorialImageFiles] = useState<File[]>();

  const { createTutorialRequestLoading, createTutorialRequestError, createTutorialResponse } = useSelector(
    (state: RootState) => state.tutorials,
  );

  const onFinish = async (data: TutorialFormData) => {
    setIsLoading(true);
    data.tutorialType = tutorialType === 'QUICK_TUTORIAL' ? 'QUICK_TUTORIAL' : 'VIDEO_TUTORIAL';
    data.thumbnail = thumbnailFile;
    data.tutorialImages = tutorialImageFiles;
    console.log(data.thumbnail);
    const response = await dispatch(tutorialActions.createTutorial(data));
    if (response.meta.requestStatus == 'fulfilled'){
      navigate('../');
      notification.success({message: "Tutorial created successfully", placement: 'bottom'});
    }
    setIsLoading(false);
  };

  const handleTutorialTypeChange = (e: any) => {
    setTutorialType(e.target.value as 'QUICK_TUTORIAL' | 'VIDEO_TUTORIAL');
  };

  return (
    <Form onFinish={onFinish} initialValues={{ remember: true }}>
      <Item
        label="Tutorial Type"
        name="tutorialType"
        rules={[{ required: true, message: 'Please select a tutorial type' }]}
      >
        <Radio.Group onChange={handleTutorialTypeChange}>
          <Radio value="QUICK_TUTORIAL">Quick Tutorial</Radio>
          <Radio value="VIDEO_TUTORIAL">Video Tutorial</Radio>
        </Radio.Group>
      </Item>

      {tutorialType === 'QUICK_TUTORIAL' && (
        <>
          <Item label="Sequence" name="sequence" rules={[{ required: true, message: 'Sequence is required' }]}>
            <Input type="number" />
          </Item>
          <Item label="Title" name="title" rules={[{ required: true, message: 'Title is required' }]}>
            <Input />
          </Item>
          <Item label="Thumbnail" name="thumbnail" rules={[{ required: true, message: 'Thumbnail is required' }]}>
            <Upload
              name="thumbnail"
              maxCount={1}
              listType="picture"
              beforeUpload={() => false}
              onChange={(info) => {
                // Handle tutorialImages file changes
                const { fileList } = info;
                // Handle tutorialImages file changes
                let files: File[] = [];
                for (let i = 0; i < fileList.length; i++) {
                  files.push(fileList[i].originFileObj as File);
                }
                files = files;
                if (files.length > 0) {
                  setThumbnailFile(files[0]);
                }
              }}
            >
              <Button icon={<UploadOutlined />}>Upload Thumbnail</Button>
            </Upload>
          </Item>
          <Item
            label="Tutorial Images"
            name="tutorialImages"
            rules={[{ required: true, message: 'Tutorial images are required' }]}
          >
            <Upload
              name="tutorialImages"
              maxCount={15}
              listType="picture"
              beforeUpload={() => false}
              onChange={(info) => {
                // Handle tutorialImages file changes
                const { fileList } = info;
                // Handle tutorialImages file changes
                let files: File[] = [];
                for (let i = 0; i < fileList.length; i++) {
                  files.push(fileList[i].originFileObj as File);
                }
                files = files;
                setTutorialImageFiles(files);
              }}
            >
              <Button icon={<UploadOutlined />}>Upload Tutorial Images</Button>
            </Upload>
          </Item>
        </>
      )}

      {tutorialType === 'VIDEO_TUTORIAL' && (
        <>
          <Item label="Sequence" name="sequence" rules={[{ required: true, message: 'Sequence is required' }]}>
            <Input type="number" />
          </Item>
          <Item label="Title" name="title" rules={[{ required: true, message: 'Title is required' }]}>
            <Input />
          </Item>
          <Item label="Thumbnail" name="thumbnail" rules={[{ required: true, message: 'Thumbnail is required' }]}>
            <Upload
              maxCount={1}
              listType="picture"
              beforeUpload={() => false}
              onChange={(info) => {
                // Handle tutorialImages file changes
                const { fileList } = info;
                // Handle tutorialImages file changes
                let files: File[] = [];
                for (let i = 0; i < fileList.length; i++) {
                  files.push(fileList[i].originFileObj as File);
                }
                files = files;
                if (files.length > 0) {
                  setThumbnailFile(files[0]);
                }}}
            >
              <Button icon={<UploadOutlined />}>Upload Thumbnail</Button>
            </Upload>
          </Item>
          <Item label="Video URL" name="videoUrl" rules={[{ required: true, message: 'Video URL is required' }]}>
            <Input />
          </Item>
          <Item label="Duration" name="duration" rules={[{ required: true, message: 'Duration is required' }]}>
            <Input />
          </Item>
        </>
      )}

      <Item>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Submit
        </Button>
      </Item>
    </Form>
  );
};

export default CreateTutorialForm;
