import React from 'react';
import Login from '../pages/login/Login';

const LoginRouter = () => {
  return (
    <Login />
  );
};

export default LoginRouter;
