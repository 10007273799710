import React from 'react';
import { Route, Routes } from 'react-router';
import MarketplaceOrdersList from '../pages/dashboard/marketplace/MarketplaceOrdersList';
import Dashboard from '../pages/dashboard/Dashboard';
import ContestEntriesView from '../pages/dashboard/contests/ContestEntriesView';
import ReportView from '../pages/Reports/ReportView';

const DashboardRouter = () => {
  return (
    <Routes>
      <Route path='/' element={<Dashboard/>}/>
      <Route path='/marketplace-orders' element={<MarketplaceOrdersList/>}/>
      <Route path='/yushoutout-contest-records/:contestId' element={<ContestEntriesView/>}/>
    </Routes>
  );
};

export default DashboardRouter;
