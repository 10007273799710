import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addAccessToken, loginUser } from '../../data/reducers/auth.reducer';
import { useMsal, useAccount } from '@azure/msal-react';

import { constants } from '../../config';
import { AccountInfo } from '@azure/msal-browser';
import { useNavigate } from 'react-router';

import { Form, Input, Button, Row, Col, Typography, Space, Card } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { instance, accounts, inProgress } = useMsal();

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      instance
        .acquireTokenSilent({
          scopes: constants.msalScopes || [''],
          account: accounts[0] as AccountInfo,
        })
        .then((response: any) => {
          if (response) {
            localStorage.setItem(constants.KEY_AUTH_TOKEN, response.accessToken);
            dispatch(addAccessToken(response.accessToken));
          }
        });
    } else {
      dispatch(addAccessToken(''));
    }
  }, [instance, accounts]);

  const loginHandle = async () => {
    instance.loginRedirect();
  };

  const { Title } = Typography;

  return (
    <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
      <Card>
      <Col span={8}>
        <Space direction="vertical" align="center" size="large">
          <img src="https://images.squarespace-cdn.com/content/v1/601beab2e81902053a46e64c/1614056664647-GBD3BCUKXSDAM3E02QT8/Yufin+Logo%40PNG.png" alt="Company Logo" style={{ height: '80px' }} />
          <Title level={3}>Admin Portal</Title>

          <Button type="primary" htmlType="submit" block onClick={() => loginHandle()}>
            Login with Microsoft
          </Button>
        </Space>
      </Col>
      </Card>
    </Row>
  );

  // return (
  //   <div>
  //     <p>Sign in with MSFT account</p>
  //     <button name="submit-btn" onClick={() => loginHandle()}>
  //       SIGN IN
  //     </button>
  //   </div>
  // );
};

export default Login;
