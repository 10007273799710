import React from 'react';
import { Route, Routes } from 'react-router';
import MarketplaceOrdersList from '../pages/dashboard/marketplace/MarketplaceOrdersList';
import Dashboard from '../pages/dashboard/Dashboard';
import ContestEntriesView from '../pages/dashboard/contests/ContestEntriesView';
import CreateTutorialForm from '../pages/tutorials/CreateTutorialForm';
import Tutorials from '../pages/tutorials/Tutorials';

const TutorialsRouter = () => {
  return (
    <Routes>
      <Route path='/' element={<Tutorials/>}/>
      <Route path='/create' element={<CreateTutorialForm/>}/>
      <Route path='/quick-tutorials' element={<ContestEntriesView/>}/>
      <Route path='/video-tutorials' element={<ContestEntriesView/>}/>
    </Routes>
  );
};

export default TutorialsRouter;
