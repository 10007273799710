import React from 'react';
import { Layout, Avatar, Dropdown, Menu } from 'antd';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router';

const { Header } = Layout;

const AppHeader = () => {
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();

  const userMenu = (
    <Menu theme="light">
      <Menu.Item key="profile" onClick={() => navigate('/profile')}>
        <UserOutlined /> Profile
      </Menu.Item>
      <Menu.Item
        key="logout"
        onClick={() => {
          instance.logoutRedirect();
        }}
      >
        <LogoutOutlined /> Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className="header">
      <div className="header-content">
        <Dropdown overlay={userMenu} placement="bottomRight">
          <div className="user-avatar">
            <Avatar size="small" icon={<UserOutlined />} />
            <span className="user-name">{accounts.length > 0 && accounts[0].name}</span>
          </div>
        </Dropdown>
      </div>
    </Header>
  );
};

export default AppHeader;
