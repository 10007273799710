import axios from 'axios';
import { constants } from '../../config';
import { TipsFormData, TutorialFormData } from '../models/tutorial.model';
import { del, error, get, post, success } from './services.common';


export const GetListOfPromosService = async (params: {
    key?: string;
    storeCode: string;
    storeId: string;
}) => {
    // Construct the URL with template literals
    const url =
        constants.endpoint +
        `/Data/merchant-stores?key=W9BBwlJHtV7e1gli2m0PHEUUDyrwEOqVyBzqI9hBRbVIePXKZYDc0l6F9R9Jn1Mo&storeCode=${params.storeCode}&storeId=${params.storeId}`;
    
    // Make the GET request
    const response = await get(url, {});
    return response;
};

export const fetchTutorialsByTypeService = async (data: { tutorialType: string }) => {
    const url =
      constants.endpoint +
      `/community/tutorials/list-admin?key=W9BBwlJHtV7e1gli2m0PHEUUDyrwEOqVyBzqI9hBRbVIePXKZYDc0l6F9R9Jn1Mo&tutorialType=${data.tutorialType}`;
    const response = await get(url, {});
    return response;
  };
  
  export const createTutorialService = async (data: TutorialFormData) => {
    const url = `${constants.endpoint}/community/tutorials/create?key=W9BBwlJHtV7e1gli2m0PHEUUDyrwEOqVyBzqI9hBRbVIePXKZYDc0l6F9R9Jn1Mo`;
  
    // Create a new FormData object
    const formData = new FormData();
  
    // Append form fields to the FormData object
    formData.append('tutorialType', data.tutorialType);
    formData.append('sequence', (data.sequence || 0).toString());
    formData.append('title', data.title);
  
    // Append files to the FormData object
    if (data.thumbnail) {
      formData.append('thumbnail', data.thumbnail);
    }
  
    if (data.tutorialImages && data.tutorialImages.length > 0) {
      for (let i = 0; i < data.tutorialImages.length; i++) {
        formData.append('tutorialImages', data.tutorialImages[i]);
      }
    }
  
    if (data.videoUrl) {
      formData.append('videoUrl', data.videoUrl);
    }
  
    if (data.duration) {
      formData.append('duration', data.duration);
    }
  
    // Send the FormData object in the Axios POST request
    try {
      const response = await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type for FormData
        },
      });
  
      return success(response.data);
    } catch (e) {
      // Handle error
      return error(e);
    }
  };
  