import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { GetListOfPromosService } from '../services/yuorderpromo.service';
import { PromoResponse } from '../models/yuorderpromos.model';


export const GetListOfPromos = createAsyncThunk(
    'youorder/getpromos', async (params: { key: string; storeCode: string;storeId: string;}, { rejectWithValue }) => {
      try {
        const response = await GetListOfPromosService(params);
        return response;
      } catch (error) {
        return rejectWithValue('Failed to fetch merchant stores');
      }
    }
  );
  // export const CreateyuOrderPromo = createAsyncThunk('tutorials/create', async (payload: TutorialFormData, thunkAPI) => {
  //   const response: any = await CreateyuOrderPromoService(payload);
  //   if (response.isSuccessful) {
  //     return response.data;
  //   } else {
  //     thunkAPI.rejectWithValue("Couldn't create tutorial");
  //     throw new Error("Couldn't create tutorial");
  //   }
  // });

  const yuOrderPromoSlice = createSlice({
    name: 'yuorderpromos',
    initialState: {
      yuorderlist: <PromoResponse[]>[],
      OrdersLoading: false,
      OrdersError: false,
    
    },
    reducers: {},
    extraReducers: {
      [GetListOfPromos.pending.type]: (state, action) => {
        state.OrdersLoading = true;
        state.OrdersError = false;
      },
      [GetListOfPromos.fulfilled.type]: (state, action) => {
        state.OrdersLoading = false;
        state.OrdersError = false;
        state.yuorderlist = action.payload.data;
      },
      [GetListOfPromos.rejected.type]: (state, action) => {
        state.OrdersLoading = false;
        state.OrdersError = true;
      },   
      // [createTutorial.rejected.type]: (state, action) => {
      //   state.createTutorialRequestLoading = true;
      //   state.createTutorialRequestError = false;
      // },
      // [createTutorial.fulfilled.type]: (state, action) => {
      //   state.createTutorialRequestLoading = false;
      //   state.createTutorialRequestError = false;
      // },
      // [createTutorial.rejected.type]: (state, action) => {
      //   state.createTutorialRequestLoading = false;
      //   state.createTutorialRequestError = true;
      // },
    },
  });
  
  export default yuOrderPromoSlice.reducer;
  