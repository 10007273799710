import React, { useEffect } from 'react';
import { Button, Card, Table } from 'antd';
import { useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { RootState } from '../../../data/reducers';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../data/storeConfig';
import * as dashboardActions from '../../../data/reducers/dashboard.reducer';
import { MarketplaceOrder, Product, ShippingAddress } from '../../../data/models/marketplace.model';
import { FileExcelOutlined } from '@ant-design/icons';

const MarketplaceOrdersList = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(dashboardActions.fetchMarketplaceorders());
  }, []);

  const { marketplaceOrders, marketplaceOrdersLoading, marketplaceOrdersError } = useSelector(
    (state: RootState) => state.dashboard,
  );

  // Define table columns based on your data structure
  const columns = [
    {
      title: 'Order ID',
      dataIndex: 'purchaseOrderId',
      key: 'purchaseOrderId',
      render: (purchaseOrderId: string) => <div style={{ maxWidth: 100 }}>{purchaseOrderId}</div>,
    },
    {
      title: 'Order Date',
      dataIndex: 'orderTimestamp',
      key: 'orderTimestamp',
      render: (orderTimestamp: Date) =>
        new Date(orderTimestamp).toLocaleString('en-PH', {
          timeZone: 'Asia/Manila',
        }),
    },
    {
      title: 'Store Name',
      dataIndex: ['shopperAccount', 'shopperStoreName'],
      key: 'shopperStoreName',
    },
    {
      title: 'Customer Name',
      dataIndex: ['shopperAccount', 'fullName'],
      key: 'shopperAccount',
    },
    {
      title: 'Phone Number',
      dataIndex: ['shopperAccount', 'phoneNumber'],
      key: 'shopperPhoneNumber',
    },
    {
      title: 'Shipping Address',
      dataIndex: 'shippingAddress',
      key: 'shippingAddress',
      render: (shippingAddress: ShippingAddress) =>
        `${shippingAddress.addressLine1}, ${shippingAddress.addressLine2}, ${shippingAddress.landmark}, ${shippingAddress.locality}, ${shippingAddress.city}`,
    },
    {
      title: 'Products',
      dataIndex: 'products',
      key: 'products',
      render: (products: Product[]) =>
        products
          .map(
            (product) =>
              `${product.cartQuantity} x ${product.productName} (₱${product.sellingPrice.toLocaleString('en-IN')})`,
          )
          .join(', '),
    },
    {
      title: 'Order Status',
      dataIndex: 'orderStatus',
      key: 'orderStatus',
    },
    {
      title: 'Fulfillment Type',
      dataIndex: 'fulfilmentType',
      key: 'fulfilmentType',
    },
  ];

  // Calculate total number of orders
  const totalOrders = marketplaceOrders.length;

  // Calculate total fulfilled orders (CLOSED)
  const totalFulfilledOrders = marketplaceOrders.filter((order: any) => order.orderStatus === 'CLOSED').length;

  // Calculate total revenue
  const totalRevenue = marketplaceOrders.filter((order: any) => order.orderStatus !== 'CANCELLED').reduce((acc: any, order: any) => acc + order.total, 0);

  function exportTableToExcel() {
    const workbook = XLSX.utils.book_new();
    let finalDataForExport: any = [];
    finalDataForExport = [];
    marketplaceOrders.forEach((order: MarketplaceOrder) => {
      let data: any = {};
      data = data;
      (data.orderId = order.purchaseOrderId),
        (data.orderDate = new Date(order.orderTimestamp).toLocaleString('en-PH', {
          timeZone: 'Asia/Manila',
        })),
        (data.storeName = order.shopperAccount.shopperStoreName),
        (data.customerName = order.shopperAccount.fullName),
        (data.phoneNumber = order.shopperAccount.phoneNumber),
        (data.shippingAddress = `${order.shippingAddress.addressLine1}, ${order.shippingAddress.addressLine2}, ${order.shippingAddress.landmark}, ${order.shippingAddress.locality}, ${order.shippingAddress.city}`),
        (data.products = order.products
          .map(
            (product) =>
              `${product.cartQuantity} x ${product.productName} (₱${product.sellingPrice.toLocaleString('en-IN')})`,
          )
          .join(', ')),
        (data.total = order.total),
        (data.orderStatus = order.orderStatus),
        (data.fulfillmentType = order.fulfilmentType);
      finalDataForExport.push(data);
    });
    // Convert your AntD table data to an Excel sheet
    const worksheet = XLSX.utils.json_to_sheet(finalDataForExport);

    // Add the sheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Save the workbook to an Excel file
    XLSX.writeFile(workbook, 'exported_data.xlsx');
  }

  return (
    <div className="marketplace-orders-container">
      <h1>Marketplace Orders</h1>
      <div className="dashboard-tiles">
        <Card title="Total Orders" className="dashboard-tile">
          <span style={{fontSize: 25}}>{totalOrders?.toLocaleString()}</span>
        </Card>
        <Card title="Fulfilled Orders (CLOSED)" className="dashboard-tile">
          <span style={{fontSize: 25}}>{totalFulfilledOrders?.toLocaleString()}</span>
        </Card>
        <Card title="Total Revenue" className="dashboard-tile">
          <span style={{fontSize: 25}}>₱{totalRevenue?.toLocaleString('en-IN')}</span>
        </Card>
      </div>
      <div className="order-table" style={{ marginTop: 25,display: 'flex', flexDirection: 'column' }}>
        
      <Button type="primary" icon={<FileExcelOutlined />} onClick={() => exportTableToExcel()} style={{ alignSelf: 'flex-end', marginBottom: 10 }} title='Export to Excel'>Export to Excel</Button>

        <Table columns={columns} dataSource={marketplaceOrders} id="marketplace-orders-table" sticky={true} />
      </div>
    </div>
  );
};

export default MarketplaceOrdersList;
