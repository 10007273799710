import React, { useEffect } from 'react';
import { Card, Button, Tabs } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../data/storeConfig';
import * as tutorialActions from '../../data/reducers/tutorials.reducer';
import QuickTutorialsListView from './QuickTutorialsListView';
import { RootState } from '../../data/reducers';
import VideoTutorialsListView from './VideoTutorialsListView';

const { TabPane } = Tabs;

const Tutorials = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { quickTutorials, videoTutorials } = useSelector((state: RootState) => state.tutorials);

  useEffect(() => {
    dispatch(tutorialActions.fetchTutorialsByType({ tutorialType: 'QUICK_TUTORIAL' }));
    dispatch(tutorialActions.fetchTutorialsByType({ tutorialType: 'VIDEO_TUTORIAL' }));
  }, []);

  return (
    <div className="dashboard-container">
      <h1>Tutorials</h1>
      <div className="dashboard-tiles">
        <Card title="Quick Tutorials" className="dashboard-tile">
          <span>{quickTutorials.length}</span>
        </Card>
        <Card title="Video Tutorials" className="dashboard-tile">
          <span>{videoTutorials.length}</span>
        </Card>
      </div>
      <div className="unique-button-container" style={{ marginBottom: 20 }}>
        <Link to="/tutorials/create">
          <Button type="primary" size="large">
            Add new tutorial
          </Button>
        </Link>
      </div>
      <Tabs defaultActiveKey="quickTutorials" type="card">
        <TabPane tab="Quick Tutorials" key="quickTutorials">
          <QuickTutorialsListView />
        </TabPane>
        <TabPane tab="Video Tutorials" key="videoTutorials">
          <VideoTutorialsListView />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Tutorials;
