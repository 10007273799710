import { constants } from "../../config";

export const checkLoginAPI = () => {
  const token = localStorage.getItem(constants.KEY_AUTH_TOKEN);
  if (token === undefined || token === null) {
      return {
          loggedIn: false,
      };
  } else {
      // check if token is expired
      // const jsonToken = decode(token, { complete: true });
      return {
          loggedIn: true
      };
    //   if (jsonToken?.exp < (new Date().getTime() + 1) / 1000) {
    //       return {
    //           loggedIn: false
    //       };
    //   }
    //   else {
    //       return {
    //           loggedIn: true,
    //           access_token: localStorage.getItem('AUTH_TOKEN')
    //       };
    //   }
  }
};

export const loginAuthenticationAPI = () => {
  return {
      loggedIn: true
  };
};

export const logoutUserAPI = async () => {
  localStorage.clear();
  return true;
};
