import axios from 'axios';
import { constants } from '../../config';
import { TipsFormData, TutorialFormData } from '../models/tutorial.model';
import { del, error, get, post, success } from './services.common';



export const merchantStoreService = async (params: {
  key: string;
  page?: number;
  pageSize?: number;
  refreshReport: boolean;
  ambassadorReferralId?: string;
  startDate: string;
  endDate: string;
}) => {
  
  let url = `${constants.endpoint}/reporting/Data/merchant-stores?key=${params.key}&startDate=${params.startDate}&endDate=${params.endDate}`;


  if (params.page) {
    url += `&pageNumber=${params.page}`;
  }
  if (params.pageSize) {
    url += `&pageSize=${params.pageSize}`;
  }

 
  url += `&refreshReport=${params.refreshReport.toString()}`;

  if (params.ambassadorReferralId) {
    url += `&ambassadorReferralId=${encodeURIComponent(params.ambassadorReferralId)}`;
  }

 
  const response = await get(url, {});
  return response;
};

