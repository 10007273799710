import React, { useEffect } from 'react';
import LoginRouter from './login.router';
import DashboardRouter from './dashboard.router';

import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { Configuration, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { constants } from '../config';
import { Navigate, Route, Routes } from 'react-router';
import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import NavigationShell from '../shared/common/NavigationShell';
import AppHeader from '../shared/common/AppHeader';
import Profile from '../pages/profile/Profile';
import TutorialsRouter from './tutorials.router';
import TipsAndTricksRouter from './tipsAndTricks.router';
import ReportsRouter from './reports.router';

// MSAL configuration
const configuration: Configuration = {
  auth: {
    clientId: constants.msalClientId || '',
    authority: constants.msalAuthority,
    redirectUri: `${window.location.origin}/dashboard`,
    postLogoutRedirectUri: `${window.location.origin}/login`,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
  // scopes: ['https://graph.microsoft.com/.default'],
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            console.log(message);
            break;
        }
      },
      piiLoggingEnabled: false,
    },
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0,
    asyncPopups: false,
  },
};

const pca = new PublicClientApplication(configuration);

const PrimaryRoutes = ({}) => {
  return (
    <MsalProvider instance={pca}>
      <AuthenticatedTemplate>
        <Layout style={{ minHeight: '100vh' }}>
          <NavigationShell />
          <Layout className="site-layout">
            <AppHeader />
            <Content style={{ margin: '16px', height: '100%' }}>
              <Routes>
                <Route path="/" element={<Navigate to={'/dashboard'} />} />
                <Route path="/login" element={<Navigate to={'/dashboard'} />} />
                <Route path="/dashboard/*" element={<DashboardRouter />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/tutorials/*" element={<TutorialsRouter />} />
                <Route path="/tipsAndTricks/*" element={<TipsAndTricksRouter />} />
                <Route path="/reports/*" element={<ReportsRouter />} />
                <Route path="*" element={<Navigate to={'/dashboard'} />} />
              </Routes>
            </Content>
          </Layout>
        </Layout>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Routes>
          <Route path="/" element={<Navigate to={'/login'} />} />
          <Route path="/login" element={<LoginRouter />} />
        </Routes>
      </UnauthenticatedTemplate>
    </MsalProvider>
  );
};

export default PrimaryRoutes;
