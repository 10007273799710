import React from 'react';
import UserProfile from './UserProfile';
import { useMsal } from '@azure/msal-react';

const Profile = () => {
    const {accounts} = useMsal();

    return accounts.length > 0 ? <div>
        <UserProfile email={accounts[0].username} name={accounts[0].name ?? ''} imageUrl={null}/>
    </div> : <></>
}

export default Profile;