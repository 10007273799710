import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { TipsAndTricks, TipsFormData, Tutorial, TutorialFormData } from '../models/tutorial.model';
import { merchantStoreService } from '../services/fsareport.service';
import {  MerchantStoreData, MerchantStoreResponse } from '../models/reportMerchant.model';
import { MerchantStore } from '../models/reportMerchant.model';




export const GetMerchantStoresReport = createAsyncThunk(
    'merchantStores/fetchMerchantStores', async (params: { key: string;page?: number; pageSize?: number;refreshReport:boolean; ambassadorReferralId?:string; startDate: string;endDate: string;
    }, { rejectWithValue }) => {
      try {
        const response = await merchantStoreService(params);
        return response;
      } catch (error) {
        return rejectWithValue('Failed to fetch merchant stores');
      }
    }
  );

  const reportSlice = createSlice({
    name: 'fsaMerchantreport',
    initialState: {
      merchantReport:<MerchantStoreData>{},
      fsareport:<MerchantStore[]>[],
      marketplaceOrdersLoading: false,
      marketplaceOrdersError: false,
    
    },
    reducers: {},
    extraReducers: {
      [GetMerchantStoresReport.pending.type]: (state, action) => {
        state.marketplaceOrdersLoading = true;
        state.marketplaceOrdersError = false;
      },
      [GetMerchantStoresReport.fulfilled.type]: (state, action) => {
        state.marketplaceOrdersLoading = false;
        state.marketplaceOrdersError = false;
        state.merchantReport = action.payload.data.data;
        state.fsareport = action.payload.data.data.data;
      },
      [GetMerchantStoresReport.rejected.type]: (state, action) => {
        state.marketplaceOrdersLoading = false;
        state.marketplaceOrdersError = true;
      },   
    },
  });
  
  export default reportSlice.reducer;
  