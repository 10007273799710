import React, { useEffect, useState } from 'react';
import { Form, Input, Radio, Button, Upload, message, notification } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { TipsFormData, TutorialFormData } from '../../../data/models/tutorial.model';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../data/reducers';
import * as tutorialActions from '../../../data/reducers/tutorials.reducer';
import { AppDispatch } from '../../../data/storeConfig';
import { useNavigate } from 'react-router';

const { Item } = Form;

const CreateTipsForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState(false);
  const [thumbnailFile, setThumbnailFile] = useState<File>();
  const [imageFiles, setImageFiles] = useState<File[]>();

  const { createTutorialRequestLoading, createTutorialRequestError, createTutorialResponse } = useSelector(
    (state: RootState) => state.tutorials,
  );

  const onFinish = async (data: TipsFormData) => {
    setIsLoading(true);
    data.thumbnail = thumbnailFile;
    data.images = imageFiles;
    console.log(data.thumbnail);
    const response = await dispatch(tutorialActions.createTip(data));
    if (response.meta.requestStatus == 'fulfilled'){
      navigate('../');
      notification.success({message: "Record created successfully", placement: 'bottom'});
    }
    setIsLoading(false);
  };

  return (
    <Form onFinish={onFinish} initialValues={{ remember: true }}>

        <>
          <Item label="Sequence" name="sequence" rules={[{ required: true, message: 'Sequence is required' }]}>
            <Input type="number" />
          </Item>
          <Item label="Title" name="title" rules={[{ required: true, message: 'Title is required' }]}>
            <Input type="text" />
          </Item>
          <Item label="Thumbnail" name="thumbnail" rules={[{ required: true, message: 'Thumbnail is required' }]}>
            <Upload
              name="thumbnail"
              maxCount={1}
              listType="picture"
              beforeUpload={() => false}
              onChange={(info) => {
                // Handle tutorialImages file changes
                const { fileList } = info;
                // Handle tutorialImages file changes
                let files: File[] = [];
                for (let i = 0; i < fileList.length; i++) {
                  files.push(fileList[i].originFileObj as File);
                }
                files = files;
                if (files.length > 0) {
                  setThumbnailFile(files[0]);
                }
              }}
            >
              <Button icon={<UploadOutlined />}>Upload Thumbnail</Button>
            </Upload>
          </Item>
          <Item
            label="Images"
            name="images"
            rules={[{ required: true, message: 'Images are required' }]}
          >
            <Upload
              name="images"
              maxCount={15}
              listType="picture"
              beforeUpload={() => false}
              onChange={(info) => {
                
                const { fileList } = info;
                
                let files: File[] = [];
                for (let i = 0; i < fileList.length; i++) {
                  files.push(fileList[i].originFileObj as File);
                }
                files = files;
                setImageFiles(files);
              }}
            >
              <Button icon={<UploadOutlined />}>Upload Images</Button>
            </Upload>
          </Item>
        </>
      <Item>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Submit
        </Button>
      </Item>
    </Form>
  );
};

export default CreateTipsForm;
