import React, { useEffect } from 'react';
import { Card, Button, Tabs } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../data/storeConfig';
import * as tutorialActions from '../../../data/reducers/tutorials.reducer';
import QuickTutorialsListView from './TipsListView';
import { RootState } from '../../../data/reducers';
import TipsListView from './TipsListView';

const { TabPane } = Tabs;

const TipsAndTricks = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { tips } = useSelector((state: RootState) => state.tutorials);

  useEffect(() => {
    dispatch(tutorialActions.fetchTips());
  }, []);

  return (
    <div className="dashboard-container">
      <h1>Tips And Tricks</h1>
      <div className="unique-button-container" style={{ marginBottom: 20 }}>
        <Link to="/tipsAndtricks/create">
          <Button type="primary" size="large">
            Add new record
          </Button>
        </Link>
      </div>
      <Tabs defaultActiveKey="tipsAndTricks" type="card">
        <TabPane tab="Tips and tricks" key="tipsAndTricks">
          <TipsListView />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default TipsAndTricks;
