import React, { useEffect } from 'react';
import { Card, Button } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import './Dashboard.css'
import { useMsal } from '@azure/msal-react';
import { constants } from '../../config';

const Dashboard = () => {
  const {accounts} = useMsal();
  const navigate = useNavigate();
  
  useEffect(() => {
    if(accounts.length === 0){
      navigate('/login');
    }
  }, []);

  return (
    <div className="dashboard-container">
      <h1>Dashboard</h1>
      <div className="dashboard-tiles">
        <Card title="Total Merchants" className="dashboard-tile">
          <span>NA</span>
        </Card>
        <Card title="Gross Transaction Value" className="dashboard-tile">
          <span>NA</span>
        </Card>
        {/* Add more tiles as needed */}
      </div>
      <div className="unique-button-container">
        <Link to="/dashboard/marketplace-orders">
          <Button type="primary" size="large">
            View Marketplace Orders
          </Button>
        </Link>
      </div>
      <div className="unique-button-container">
        <Link to={`/dashboard/yushoutout-contest-records/${constants.YUSHOUTOUT_ID}`}>
          <Button type="primary" size="large">
            View yushoutout Contest Entries
          </Button>
        </Link>
      </div>
      <div className="unique-button-container">
        <Link to={`/dashboard/yushoutout-contest-records/${constants.SELFIE_CONTEST_ID}`}>
          <Button type="primary" size="large">
            View Selfie Contest Entries
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default Dashboard;
