import React from 'react';
import { Card, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const { Meta } = Card;

const UserProfile = (props: { name: string; email: string; imageUrl: string | null }) => {
  return (
    <Card style={{ width: 300 }}>
      <Meta avatar={<Avatar size="large" icon={<UserOutlined />} />} title={props.name} description={props.email} />
    </Card>
  );
};

export default UserProfile;
